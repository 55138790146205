define("dash/services/affiliate", ["exports", "country-iso-3-to-2"], function (_exports, _countryIso3To) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    dash: Ember.inject.service(),
    getCommissionPercentage() {
      return Ember.get(this, 'ajax').request('/affiliate/commission', {
        method: 'GET'
      }).then(res => {
        return res.commission_percentage;
      });
    },
    _prepareCreateAffiliateRequestData(addressObj, password, email) {
      const addressString = addressObj.address_2 ? `${addressObj.address_1} ${addressObj.address_2}` : addressObj.address_1;
      return {
        data: {
          type: 'affiliate',
          attributes: {
            firstname: addressObj.given_name,
            lastname: addressObj.family_name,
            password: password,
            email: email,
            address: {
              address: addressString,
              postal_code: addressObj.zip,
              city: addressObj.city,
              state: addressObj.state,
              country: {
                // tapfiliate uses 2-letter country ISO codes
                code: (0, _countryIso3To.default)(addressObj.country.code)
              }
            }
          }
        }
      };
    },
    getAffiliateCommissionRate() {
      const ajaxService = Ember.get(this, 'ajax');
      return ajaxService.request('/affiliate/commission', {
        method: 'GET'
      });
    },
    createAffiliate(addressObj, password, email) {
      const ajaxService = Ember.get(this, 'ajax');
      const data = this._prepareCreateAffiliateRequestData(addressObj, password, email);
      return ajaxService.request('/affiliate', {
        method: 'POST',
        data
      }).then(() => {
        const dashService = Ember.get(this, 'dash');
        return dashService.fetchCurrentUser();
      });
    },
    setAffiliatePaypal(paypalAddress) {
      const ajaxService = Ember.get(this, 'ajax');
      const affiliateId = Ember.get(this, 'dash.currentUser.affiliate.tapfiliate_id');
      const data = {
        data: {
          type: 'affiliate',
          attributes: {
            affiliate_id: affiliateId,
            paypal_address: paypalAddress
          }
        }
      };
      return ajaxService.request('/affiliate/paypal', {
        method: 'PUT',
        data
      }).then(() => {
        const dashService = Ember.get(this, 'dash');
        return dashService.fetchCurrentUser();
      });
    },
    addAffiliateToProgram() {
      const ajaxService = Ember.get(this, 'ajax');
      const affiliateId = Ember.get(this, 'dash.currentUser.affiliate.tapfiliate_id');
      const data = {
        data: {
          type: 'affiliate',
          attributes: {
            affiliate_id: affiliateId
          }
        }
      };
      return ajaxService.request('/affiliate/program', {
        method: 'PUT',
        data
      }).then(() => {
        const dashService = Ember.get(this, 'dash');
        return dashService.fetchCurrentUser();
      });
    },
    toggleEmailNotifications() {
      const affiliateId = Ember.get(this, 'dash.currentUser.affiliate.tapfiliate_id');
      const isEmailNotificationsEnabled = Ember.get(this, 'dash.currentUser.affiliate.emails_enabled');
      const data = {
        data: {
          attributes: {
            affiliate_id: affiliateId,
            emails_enabled: !isEmailNotificationsEnabled
          }
        }
      };
      const ajaxService = Ember.get(this, 'ajax');
      return ajaxService.request('/affiliate/emails_enabled', {
        method: 'PUT',
        data
      }).then(() => {
        const dashService = Ember.get(this, 'dash');
        return dashService.fetchCurrentUser();
      });
    }
  });
  _exports.default = _default;
});