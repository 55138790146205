define("dash/lib/calendar", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    account: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    appPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /[a-z]{4}-[a-z]{4}-[a-z]{4}-[a-z]{4}|[a-z]{16}/g,
      message: 'This field must be a valid app-specific password'
    })]
  });
  var _default = Ember.Object.extend(Validations, {
    account: '',
    appPassword: '',
    serialize() {
      if (!Ember.get(this, 'validations.isValid')) return {};
      return {
        account: Ember.get(this, 'account'),
        appPassword: Ember.get(this, 'appPassword')
      };
    }
  });
  _exports.default = _default;
});