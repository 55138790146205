define("dash/routes/account/referrals/metrics", ["exports", "dash/lib/authenticatedRoute", "dash/routes/account/referrals/intro", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _intro, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    affiliate: Ember.inject.service(),
    beforeModel() {
      const currentUser = Ember.get(this, 'dash.currentUser');
      (0, _intro.transitionReferrals)(route => this.transitionTo(route), currentUser);
    },
    model() {
      const currentUser = Ember.get(this, 'dash.currentUser');
      return Ember.get(this, 'affiliate').getCommissionPercentage().then(res => {
        return {
          commision: res,
          affiliate: currentUser.affiliate,
          tapfiliateLink: _ExternalLinks.default.TAPFILIATE
        };
      });
    },
    actions: {
      copy(link) {
        navigator.clipboard.writeText(link);
        return Ember.get(this, 'dash').showSuccessMessage('Copied referral link to clipboard');
      },
      displayTerms() {
        return this.transitionTo('account.referrals.terms');
      },
      toggleEmailNotifications() {
        return Ember.get(this, 'affiliate').toggleEmailNotifications().then(() => {
          this.refresh();
        });
      }
    }
  });
  _exports.default = _default;
});