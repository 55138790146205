define("dash/templates/akko-insurance/associate-imeis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bPFF6gE/",
    "block": "{\"symbols\":[\"imei\",\"index\"],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"cancelPath\"],[\"Register Light Protect Plan\",\"login\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"isTokenValid\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"flex-col flex items-center\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"imeis\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"          \"],[1,[27,\"imeis-form-row\",null,[[\"imei\",\"index\",\"onRowUnfocus\",\"updateImeiAction\"],[[22,1,[]],[22,2,[]],[27,\"route-action\",[\"onRowUnfocus\"],null],[27,\"route-action\",[\"updateImei\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex-row flex items-center mb4\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"Button--hollow block mxauto mt8 mb_5\"],[12,\"disabled\",[21,\"hasEmptyInput\"]],[3,\"action\",[[22,0,[]],[27,\"route-action\",[\"submit\"],null]]],[9],[0,\"\\n          register protect plan\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"flex-col flex items-center mb4\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n          This Light Protect plan has expired. Please contact customer support\\n          if you have any questions.\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/akko-insurance/associate-imeis.hbs"
    }
  });
  _exports.default = _default;
});