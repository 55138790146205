define("dash/templates/devices/device/contacts/export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Lw1y28/e",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Export Contacts File\",\"devices.device.contacts.actions\",\"home\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container--wide text-center pt_5\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n      Download your contacts as a .vcf file which can be imported to the light\\n      dashboard or other platforms.\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt6 mb_5\"],[12,\"disabled\",[21,\"isFetching\"]],[12,\"onClick\",[27,\"route-action\",[\"fetchAndDownload\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isFetching\"]]],null,{\"statements\":[[0,\"        fetching contacts...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        download\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/export.hbs"
    }
  });
  _exports.default = _default;
});