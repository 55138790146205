define("dash/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    dash: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel() {
      const dashService = Ember.get(this, 'dash');
      if (dashService.cookies.exists('token')) {
        const token = dashService.cookies.read('token');
        Ember.set(dashService, 'currentToken', token);
      }
      if (dashService.cookies.exists('support_token')) {
        const supportToken = dashService.cookies.read('support_token');
        Ember.set(dashService, 'currentSupportToken', supportToken);
      }
    },
    model() {
      const dashService = Ember.get(this, 'dash');
      if (!dashService.currentToken && !dashService.currentSupportToken) return;
      return dashService.fetchCurrentUser().catch(errors => {
        dashService.handleServerErrors(errors);
      });
    },
    actions: {
      logOut() {
        const dashService = Ember.get(this, 'dash');
        return dashService.logOut().then(() => this.transitionTo('login'));
      }
    }
  });
  _exports.default = _default;
});