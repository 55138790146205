define("dash/services/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    createCalendar(deviceId, username, password, type, endpoint) {
      const ajaxService = Ember.get(this, 'ajax');
      const data = {
        data: {
          type: 'calendar',
          attributes: {
            device_id: deviceId,
            username,
            password
          }
        }
      };
      if (type === 'caldav') {
        data.data.attributes.endpoint = endpoint;
      } else {
        data.data.attributes.type = type;
      }
      return ajaxService.request('/calendars', {
        method: 'POST',
        data
      });
    }
  });
  _exports.default = _default;
});