define("dash/routes/devices/device/tools/calendar/index", ["exports", "dash/lib/authenticatedRoute", "dash/constants/ExternalLinks"], function (_exports, _authenticatedRoute, _ExternalLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    model() {
      const calendarDeviceTool = Ember.get(this.modelFor('devices.device'), 'deviceTools').find(deviceTool => {
        return Ember.get(deviceTool, 'tool.namespace') === 'calendar';
      });
      if (!calendarDeviceTool) {
        const tool = Ember.get(this, 'store').peekAll('tool').find(tool => tool.namespace === 'calendar');
        return {
          installed: false,
          device: this.modelFor('devices.device'),
          tool
        };
      }
      return {
        calendarDeviceTool,
        installed: true,
        supportLink: _ExternalLinks.default.SUPPORT.calendar
      };
    },
    actions: {
      installCalendarTool() {
        const dashService = Ember.get(this, 'dash');
        const {
          tool,
          device
        } = this.controller.get('model');
        return dashService.createCalendarTool({
          toolId: Ember.get(tool, 'id'),
          deviceId: Ember.get(device, 'id')
        }).then(() => {
          Ember.getOwner(this).lookup('route:devices').refresh();
        }).then(() => {
          dashService.showSuccessMessage(`${tool.title} was added to your device.`);
          this.transitionTo('devices.device.tools');
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});