define("dash/services/upload", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    init(...args) {
      this._super(...args);
      this.uploadingFiles = this.uploadingFiles || {};
    },
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    uploading: false,
    async checkRemainingCapacity(deviceToolId) {
      const ajaxService = Ember.get(this, 'ajax');
      const resp = await ajaxService.request(`/audio_capacity?device_tool_id=${deviceToolId}`);
      return {
        remainingCapacity: resp.remaining_capacity,
        totalCapacity: resp.total_capacity,
        failedCount: resp.failed_count,
        processingCount: resp.processing_count
      };
    },
    async checkRemainingCapacityWithPendingUploads(files, deviceToolId) {
      const totalSize = files.reduce((acc, {
        file
      }) => {
        return acc + file.size;
      }, 0);
      const {
        remainingCapacity
      } = await this.checkRemainingCapacity(deviceToolId);
      return remainingCapacity - totalSize;
    },
    async uploadAudioFiles(deviceToolId, files) {
      Ember.set(this, 'uploading', true);
      Ember.get(this, 'notify').success(`Beginning upload of ${files.length} files.`);
      const uploadingFiles = files.reduce((acc, val) => {
        acc[val.name] = {
          status: 'uploading'
        };
        return acc;
      }, {});
      Ember.set(this, 'uploadingFiles', uploadingFiles);
      for (let index = 0; index < files.length; index++) {
        await this.uploadAudioFile(deviceToolId, files[index]);
      }
    },
    isFileTypeAccepted(type) {
      return type.includes('audio') && (type.includes('mpeg') || type.includes('mp3') || type.includes('mp4') || type.includes('m4a') || type.includes('ogg') || type.includes('vorbis') || type.includes('flac') || type.includes('aiff') || type.includes('wma'));
    },
    uploadAudioFile(deviceToolId, {
      file,
      name
    }) {
      const ajaxService = Ember.get(this, 'ajax');
      const data = {
        data: {
          type: 'audios',
          attributes: {
            filename: name,
            device_tool_id: deviceToolId
          }
        }
      };
      return ajaxService.request('/audios', {
        method: 'POST',
        data: data
      }).then(res => {
        const {
          presigned_url
        } = res.included[0].attributes;
        return fetch(presigned_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });
      }).then(() => {
        const uploadingFiles = Ember.get(this, 'uploadingFiles');
        uploadingFiles[name] = {
          status: 'uploaded'
        };
        Ember.set(this, 'uploadingFiles', uploadingFiles);
        return {
          success: true
        };
      }).catch(err => {
        const uploadingFiles = Ember.get(this, 'uploadingFiles');
        uploadingFiles[name] = {
          status: 'errored',
          err
        };
        Ember.set(this, 'uploadingFiles', uploadingFiles);
        Sentry.captureException(err);
        return {
          err
        };
      }).finally(() => {
        const uploadingFiles = Ember.get(this, `uploadingFiles`);
        const uploadingFile = uploadingFiles[name];
        const {
          isComplete,
          uploading
        } = this.checkRemaining();
        let currentUploadStatusMessage;
        let notifyMode;
        if (uploadingFile.err) {
          currentUploadStatusMessage = `${name} failed to upload.`;
          notifyMode = 'warning';
        } else {
          currentUploadStatusMessage = `${name} uploaded successfully.`;
          notifyMode = 'success';
        }
        if (!isComplete) {
          const remainingMessage = `${uploading} files remaining.`;
          Ember.get(this, 'notify')[notifyMode](`${currentUploadStatusMessage} ${remainingMessage}`);
        } else {
          Ember.get(this, 'notify').success(currentUploadStatusMessage);
          Ember.get(this, 'notify').success('All Uploads complete.');
          Ember.set(this, 'uploading', false);
        }
      });
    },
    checkRemaining() {
      const uploadingFilesEntries = Object.entries(Ember.get(this, 'uploadingFiles'));
      return uploadingFilesEntries.reduce((acc, [fileName, {
        status
      }]) => {
        if (status === 'errored') {
          acc.errored.push(fileName);
        }
        if (status === 'uploading') {
          acc.isComplete = false;
          acc.uploading += 1;
        }
        return acc;
      }, {
        isComplete: true,
        uploading: 0,
        errored: []
      });
    },
    deleteAll(deviceToolId) {
      const ajaxService = Ember.get(this, 'ajax');
      return ajaxService.request('/audios/delete_all', {
        method: 'POST',
        data: {
          device_tool_id: deviceToolId
        }
      });
    }
  });
  _exports.default = _default;
});