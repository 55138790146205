define("dash/adapters/device-tool", ["exports", "dash/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    createRecord(store, type, snapshot) {
      const saving = this._super(store, type, snapshot);
      return new Promise((resolve, reject) => {
        /*
          In the case we are restoring a record that was soft-deleted, EmberData will
          throw an exception because it thinks that record should be deleted!
          So, we check to see if the new record matches any old records, and erase it
          from the ID map if needed. More info: https://github.com/emberjs/data/issues/4972
        */
        saving.then(payload => {
          const id = Ember.get(payload, 'data.id');
          const existing = store.peekRecord(snapshot.modelName, id);
          if (existing) {
            store._removeFromIdMap(existing._internalModel);
            Ember.run.next(() => {
              resolve(payload);
            });
          } else {
            resolve(payload);
          }
        }, error => reject(error));
      });
    }
  });
  _exports.default = _default;
});