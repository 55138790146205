define("dash/routes/devices/device/tools/notes/view/note", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    ajax: Ember.inject.service(),
    dash: Ember.inject.service(),
    model({
      note_id
    }) {
      Ember.set(this, 'isTextModified', false);
      const dashService = Ember.get(this, 'dash');
      let note;
      return Ember.get(this, 'store').findRecord('note', note_id, {
        reload: true
      }).then(res => {
        Ember.set(this, 'editingNote', res);
        note = res;
      }).then(() => {
        return dashService.getPreSignedNoteUrl(note_id);
      }).then(presignedUrl => {
        return dashService.getNoteText(presignedUrl);
      }).then(noteText => {
        return Ember.RSVP.hash({
          note: note,
          noteText: noteText
        });
      }).catch(err => {
        if (err.status == 404) {
          dashService.showErrorMessage('The note you selected was deleted and no longer exists.');
        } else {
          dashService.handleServerErrors(err);
        }
        this.send('refreshNotesModel');
        return this.transitionTo('devices.device.tools.notes.view');
      });
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'editingTitle', false);
      Ember.set(controller, 'deviceToolId', Ember.get(this.modelFor('devices.device.tools.notes'), 'noteDeviceTool.id'));
    },
    actions: {
      editTitle() {
        Ember.set(this, 'controller.editingTitle', true);
      },
      displayConfirmation() {
        if (Ember.get(this, 'isTextModified')) {
          let message = 'By exiting you will be discarding unsaved changes to your note. Would you like to proceed?';
          if (confirm(message) == true) {
            return this.transitionTo('devices.device.tools.notes.view');
          }
        } else {
          return this.transitionTo('devices.device.tools.notes.view');
        }
      },
      updateTitle() {
        const dashService = Ember.get(this, 'dash');
        const note = Ember.get(this, 'editingNote');
        return note.save().then(() => {
          dashService.showSuccessMessage(`Note title was updated.`);
          this.send('refreshNotesModel');
          Ember.set(this, 'controller.editingTitle', false);
        }).catch(err => {
          return dashService.handleServerErrors(err);
        });
      },
      deleteNote() {
        const dashService = Ember.get(this, 'dash');
        const note = Ember.get(this, 'editingNote');
        const noteTitle = Ember.get(note, 'title');
        let message = `Are you sure you'd like to delete '${noteTitle}'?`;
        if (confirm(message) == true) {
          note.destroyRecord().then(() => {
            sessionStorage.removeItem('noteId');
            dashService.showSuccessMessage(`${noteTitle} was deleted.`);
            this.send('refreshNotesModel');
            return this.transitionTo('devices.device.tools.notes.view');
          }).catch(err => {
            return dashService.handleServerErrors(err);
          });
        }
      },
      modifiedText() {
        Ember.set(this, 'isTextModified', true);
      },
      download(noteText, noteTitle) {
        var filename = noteTitle + '.txt';
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(noteText));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
      updateNote(noteText) {
        const note = Ember.get(this, 'editingNote');
        const ajaxService = Ember.get(this, 'ajax');
        const dashService = Ember.get(this, 'dash');
        if (Ember.get(this, 'isTextModified')) {
          return ajaxService.request(`/notes/${note.id}/generate_presigned_put_url`, {
            method: 'GET'
          }).then(res => {
            const presigned_url = res.presigned_put_url;
            return fetch(presigned_url, {
              method: 'PUT',
              body: noteText,
              headers: {
                'Content-Type': 'text/plain'
              }
            });
          }).then(() => {
            Ember.set(this, 'isTextModified', false);
            dashService.showSuccessMessage(`Note was updated.`);
            this.send('refreshNotesModel');
            return this.transitionTo('devices.device.tools.notes.view');
          }).catch(err => {
            return dashService.handleServerErrors(err);
          });
        } else {
          dashService.showSuccessMessage(`Note was updated.`);
          return this.transitionTo('devices.device.tools.notes.view');
        }
      }
    }
  });
  _exports.default = _default;
});