define("dash/templates/components/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mC9rMzAx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[3,\"action\",[[22,0,[]],\"performSearch\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex flex-row items-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"Input--primary mr1\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"aria-label\",\"type\",\"value\",\"class\"],[\"search\",\"search\",[23,[\"value\"]],\"SearchBarInput\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow mb1\"],[11,\"type\",\"submit\"],[9],[0,\"\\n        search\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/search-bar.hbs"
    }
  });
  _exports.default = _default;
});