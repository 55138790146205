define("dash/components/menu-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isMagic8Ball: Ember.computed('link.data', function () {
      const namespace = Ember.get(this, 'link.data');
      return namespace === 'magic8ball';
    })
  });
  _exports.default = _default;
});