define("dash/controllers/devices/device/tools/music/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const convertBytesToHumanReadable = bytes => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
  };
  var _default = Ember.Controller.extend({
    init(...args) {
      this._super(...args);
      this.files = this.files || [];
    },
    upload: Ember.inject.service(),
    notify: Ember.inject.service(),
    addedFileEvent: Ember.computed(function () {
      return file => {
        const {
          name,
          type
        } = file;
        if (!Ember.get(this, 'upload').isFileTypeAccepted(type)) {
          return Ember.get(this, 'notify').warning(`Cannot upload file: ${name} with type: ${type}`);
        }
        const size = convertBytesToHumanReadable(file.size);
        const files = Ember.get(this, 'files');
        // convert to alphanumeric because special characters were causing 403s on AWS.headObject
        const encodedName = file.name.replace(/[^\w.]+/g, '');
        const updatedFiles = files.concat({
          name: encodedName,
          size,
          file
        });
        // we can't avoid the mutation in computed property because of the design
        // of this dropzone component
        // eslint-disable-next-line
        Ember.set(this, 'files', updatedFiles);
      };
    }),
    options: Ember.computed(function () {
      return {
        url: '#',
        addRemoveLinks: true,
        autoProcessQueue: false,
        autoQueue: false,
        dictDefaultMessage: 'Click here or drag + drop files',
        createImageThumbnails: false
      };
    }),
    actions: {
      removeFile: function (index) {
        const files = Ember.get(this, 'files');
        const updatedFiles = files.slice(0, index).concat(files.slice(index + 1));
        Ember.set(this, 'files', updatedFiles);
      },
      upload: async function () {
        const files = Ember.get(this, 'files');
        const uploadService = Ember.get(this, 'upload');
        const remainingCapacity = await uploadService.checkRemainingCapacityWithPendingUploads(files, Ember.get(this, 'deviceToolId'));
        if (remainingCapacity < 0) {
          const warningMessage = `Requested upload exceeds available storage capacity by ${convertBytesToHumanReadable(-remainingCapacity)}. ` + 'Please remove some files here or in previously uploaded files.';
          return Ember.get(this, 'notify').warning(warningMessage);
        }
        uploadService.uploadAudioFiles(Ember.get(this, 'deviceToolId'), files);

        // this gets sent up to app/routes/devices/device/tools/music.js
        this.send('subscribeToAudioProcessingUpdates');
        Ember.set(this, 'files', []);
      }
    }
  });
  _exports.default = _default;
});