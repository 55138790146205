define("dash/controllers/devices/device/settings/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    defaultLocationText: Ember.computed('existingDeviceToolLocation', function () {
      const existingDeviceToolLocation = Ember.get(this, 'existingDeviceToolLocation');
      if (!existingDeviceToolLocation.isTruthy) {
        return 'N/A';
      }
      return `${Ember.get(existingDeviceToolLocation, 'config.address.label')}`;
    }),
    link: Ember.computed('existingDeviceToolLocation', function () {
      const existingDeviceToolLocation = Ember.get(this, 'existingDeviceToolLocation');
      if (!existingDeviceToolLocation.isTruthy) {
        return 'devices.device.settings.about-default-location';
      }
      return 'devices.device.settings.set-default-location';
    })
  });
  _exports.default = _default;
});