define("dash/templates/devices/device/contacts-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2ClsUWv3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Contacts\",\"devices.device\",\"home\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n      loading contacts...\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts-loading.hbs"
    }
  });
  _exports.default = _default;
});