define("dash/templates/devices/device/contacts/import/other", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6wdXviT8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\"],[\"Import from File\",\"devices.device.contacts.import.index\",\"home\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"stage\"]],\"IDLE\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading mb4\"],[9],[0,\"\\n        Download Your Contacts\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"sub-heading\"],[9],[0,\"\\n        You will need to download your contacts as a .vcf file from the platform\\n        you're currently using.\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt6 mb_5\"],[9],[0,\"\\n\"],[4,\"file-upload\",null,[[\"classNames\",\"name\",\"accept\",\"onfileadd\"],[\"pointer\",\"csvFile\",\"text/vcard\",[27,\"route-action\",[\"onfileadd\"],null]]],{\"statements\":[[0,\"          Upload .vcf\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\\n      \"],[7,\"p\"],[11,\"class\",\"title\"],[9],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"stage\"]],\"UPLOADING\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n        uploading...\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"stage\"]],\"SUCCESS\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading\"],[9],[0,\"\\n        We've started importing your contacts. We'll send you an email when\\n        we're done!\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/contacts/import/other.hbs"
    }
  });
  _exports.default = _default;
});