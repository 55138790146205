define("dash/templates/account/referrals/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "34f86FRr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Confirmation\",\"account.show\",\"home\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container__desktop flex flex-3 flex-col self-center sub-heading items-center mb4 md:mb8\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"my8\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"mb2\"],[9],[0,\"\\n        Thank you for signing up to be an Advocate of Light!\\n      \"],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\"\\n        Click below to generate your unique URL.\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow block mxauto mt8 mb_5\"],[12,\"disabled\",[27,\"or\",[[27,\"not\",[[23,[\"model\",\"validations\",\"isValid\"]]],null],[23,[\"isSaving\"]]],null]],[12,\"onClick\",[27,\"route-action\",[\"continue\"],null]],[9],[0,\"\\n        \"],[4,\"if\",[[23,[\"isSaving\"]]],null,{\"statements\":[[0,\"saving...\"]],\"parameters\":[]},{\"statements\":[[0,\"let's get started\"]],\"parameters\":[]}],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/account/referrals/confirm.hbs"
    }
  });
  _exports.default = _default;
});