define("dash/templates/download-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ldCt0xhg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"cancelPath\",\"withoutContainer\"],[\"login\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex flex-1 flex-col justify-center body mb4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex sub-heading text-center justify-center h-25 mb4\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Click below to download '\"],[1,[23,[\"model\",\"noteTitle\"]],false],[0,\"', an audio note\\n        forwarded from your Light Phone II.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"Button--hollow mb1 self-center\"],[12,\"onClick\",[27,\"action\",[[22,0,[]],[27,\"route-action\",[\"download\",[23,[\"model\",\"downloadLink\"]],[23,[\"model\",\"noteTitle\"]]],null]],null]],[11,\"type\",\"button\"],[9],[0,\"\\n      download note\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/download-note.hbs"
    }
  });
  _exports.default = _default;
});