define("dash/templates/devices/device/tools/notes/delete-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DTH40lm+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Delete Notes\",\"devices.device.tools.notes\",\"home\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex flex-1 flex-col self-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container--wide text-center mt4\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading flex-col mb4\"],[9],[0,\"Are you sure you want to delete all notes\\n        from the tool?\"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"heading flex-col mb4\"],[9],[0,\"You cannot undo this.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex flex-col items-center my2\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"Button--hollow mt3 mb2\"],[12,\"onClick\",[27,\"action\",[[22,0,[]],[27,\"route-action\",[\"deleteAll\"],null]],null]],[9],[0,\"\\n        Delete all notes\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/tools/notes/delete-all.hbs"
    }
  });
  _exports.default = _default;
});