define("dash/templates/devices/device/tools/music/edit/audio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "myFDdjNZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"dash-page\",null,[[\"heading\",\"backPath\",\"cancelPath\",\"withoutContainer\"],[\"Edit Name\",\"devices.device.tools.music.edit\",\"home\",true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"container--wide self-center\"],[9],[0,\"\\n    \"],[1,[27,\"music-edit-audio-form\",null,[[\"audio\",\"saveAudio\"],[[23,[\"model\"]],[27,\"route-action\",[\"saveAudio\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/devices/device/tools/music/edit/audio.hbs"
    }
  });
  _exports.default = _default;
});