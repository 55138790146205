define("dash/routes/devices/device/tools/notes/view/index", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return this.modelFor('devices.device.tools.notes.view');
    },
    actions: {
      willTransition(transition) {
        if (transition.targetName == 'devices.device.tools.notes.view.note' || transition.targetName == 'devices.device.tools.notes.view.audio-note') {
          sessionStorage.setItem('noteScrollX', window.scrollX);
          sessionStorage.setItem('noteScrollY', window.scrollY);
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});