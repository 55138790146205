define("dash/controllers/devices/device/tools/music/edit/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    dash: Ember.inject.service(),
    ajax: Ember.inject.service(),
    sortedPlaylistItems: Ember.computed('model.playlistItems.[]', function () {
      return Ember.get(this, 'model.playlistItems').sortBy('position');
    }),
    sortMode: Ember.computed('model.sortMode', function () {
      return Ember.get(this, 'model.sortMode');
    }),
    actions: {
      async changeSortMode() {
        const sortMode = Ember.get(this, 'sortMode');
        let newSortMode;
        switch (sortMode) {
          case 'rank':
            newSortMode = 'artists_desc';
            break;
          case 'artists_desc':
            newSortMode = 'artists_asc';
            break;
          default:
            newSortMode = 'rank';
        }
        const playlistId = Ember.get(this, 'model.id');
        const deviceToolId = Ember.get(this, 'deviceToolId');
        const ajaxService = Ember.get(this, 'ajax');
        await ajaxService.request('/playlists/sort_mode', {
          method: 'POST',
          data: {
            playlist_id: playlistId,
            device_tool_id: deviceToolId,
            sort_mode: newSortMode
          }
        });
        this.send('refreshPlaylistModel');
      },
      delete(playlistItem) {
        const dashService = Ember.get(this, 'dash');
        const playlist = Ember.get(this, 'model');

        // Handles deletion from system playlist only for now
        if (Ember.get(playlist, 'isSystemPlaylist')) {
          const audio = playlistItem.get('audio');
          const audioTitle = audio.get('title');
          return audio.destroyRecord() // destroy the audio record so it's removed from all playlists
          .then(() => {
            // unload the record so it's removed from the list
            Ember.get(this, 'sortedPlaylistItems').removeAt(Ember.get(playlistItem, 'position'));
            playlistItem.unloadRecord();
          }).then(() => {
            dashService.showSuccessMessage(`${audioTitle} was removed.`);
          }).catch(errors => dashService.handleServerErrors(errors)).finally(() => {
            this.send('refreshPlaylistModel');
          });
        }
      },
      onDragStart: function (playlistItem) {
        // store the id of the playlistItem the user is interacting with
        Ember.set(this, 'lastDraggedPlaylistItemId', Ember.get(playlistItem, 'id'));
      },
      sortEndAction: function () {
        const dashService = Ember.get(this, 'dash');
        const sortedPlaylistItems = this.get('sortedPlaylistItems');
        const lastDraggedPlaylistItemId = Ember.get(this, 'lastDraggedPlaylistItemId');
        if (!lastDraggedPlaylistItemId) return;
        for (let i = 0; i < sortedPlaylistItems.length; i++) {
          // find our lastDraggedPlaylistItem in the sortedPlaylistItems list
          if (sortedPlaylistItems[i].id !== lastDraggedPlaylistItemId) continue;
          const playlistItem = sortedPlaylistItems[i];
          const position = Ember.get(playlistItem, 'position');

          // if the position has changed, update the playlistItem record
          if (i !== position) {
            Ember.set(playlistItem, 'position', i);
            return playlistItem.save().catch(errors => {
              return dashService.handleServerErrors(errors);
            }).finally(() => {
              this.send('refreshPlaylistModel');
            });
          }
        }
      }
    }
  });
  _exports.default = _default;
});