define("dash/routes/devices/device/tools/notes/search", ["exports", "dash/lib/authenticatedRoute"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model(params) {
      const searchTerm = Ember.get(params, 'searchTerm');
      if (!searchTerm) {
        return {
          notes: [],
          searchedTerm: searchTerm
        };
      }
      const dashService = Ember.get(this, 'dash');
      const deviceToolId = Ember.get(this.modelFor('devices.device.tools.notes'), 'noteDeviceTool.id');
      return dashService.searchNotes(deviceToolId, searchTerm).then(notes => {
        const isEmptySearchResults = notes.length === 0;
        return {
          notes: notes,
          isEmptySearchResults: isEmptySearchResults,
          searchedTerm: searchTerm
        };
      }).catch(errors => dashService.handleServerErrors(errors));
    },
    setupController(controller) {
      this._super(...arguments);
      Ember.set(controller, 'deviceToolId', Ember.get(this.modelFor('devices.device.tools.notes'), 'noteDeviceTool.id'));
    },
    actions: {
      performSearch() {
        const searchTerm = Ember.get(this, 'controller.searchTerm');
        const dashService = Ember.get(this, 'dash');
        const deviceToolId = Ember.get(this, 'controller.deviceToolId');
        if (!searchTerm || !searchTerm.length) return;
        return dashService.searchNotes(deviceToolId, searchTerm).then(notes => {
          const isEmptySearchResults = notes.length === 0;
          Ember.set(this, 'controller.model.notes', notes);
          Ember.set(this, 'controller.model.isEmptySearchResults', isEmptySearchResults);
          Ember.set(this, 'controller.model.searchedTerm', searchTerm);
        }).catch(errors => dashService.handleServerErrors(errors));
      }
    }
  });
  _exports.default = _default;
});