define("dash/routes/signup", ["exports", "dash/lib/unauthenticatedRoute", "dash/lib/userSignup"], function (_exports, _unauthenticatedRoute, _userSignup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _unauthenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return _userSignup.default.create(Ember.getOwner(this).ownerInjection());
    },
    setupController(controller, model) {
      this._super(...arguments);
      return Ember.set(controller, 'model', model);
    },
    actions: {
      signup(evt) {
        if (evt) evt.preventDefault();
        const dashService = Ember.get(this, 'dash');
        const model = Ember.get(this, 'controller.model');
        const {
          email,
          password
        } = model;
        return dashService.signup(email, password).then(() => this._transitionAfterSignin()).catch(errors => dashService.handleServerErrors(errors));
      }
    },
    // Privado
    _transitionAfterSignin() {
      this.transitionTo('home');
    }
  });
  _exports.default = _default;
});