define("dash/templates/components/tool-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9Szgj91C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[11,\"class\",\"my1_5 md:my_75\"],[9],[0,\"\\n  \"],[7,\"button\"],[12,\"onClick\",[27,\"route-action\",[\"uninstall\",[23,[\"deviceTool\"]]],null]],[11,\"class\",\"Button--title-icon pointer\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/images/icons/warning.svg\"],[11,\"alt\",\"remove tool button\"],[11,\"data-test\",\"remove-tool-button\"],[11,\"class\",\"wh100\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isMagic8Ball\"]]],null,{\"statements\":[[4,\"link-to\",[\"devices.device.tools.tool\",[23,[\"deviceTool\",\"tool\",\"namespace\"]]],[[\"class\"],[\"pointer hover-gray-light no-wrap ml1 md:ml1_5\"]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"letter__wrapper\"],[9],[0,\"L\"],[7,\"span\"],[11,\"class\",\"heading__letter__rotater\"],[9],[0,\"a\"],[10],[0,\"ngu\"],[7,\"span\"],[11,\"class\",\"heading__letter__rotater\"],[9],[0,\"a\"],[10],[0,\"ge\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[\"devices.device.tools.tool\",[23,[\"deviceTool\",\"tool\",\"namespace\"]]],[[\"class\"],[\"pointer hover-gray-light no-wrap ml1 md:ml1_5\"]],{\"statements\":[[0,\"      \"],[1,[23,[\"deviceTool\",\"tool\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/tool-title.hbs"
    }
  });
  _exports.default = _default;
});