define("dash/routes/download-note", ["exports", "dash/lib/unauthenticatedRoute", "dash/config/environment"], function (_exports, _unauthenticatedRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _unauthenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    beforeModel(transition) {
      if (!Ember.get(transition, 'queryParams.t')) {
        Ember.get(this, 'dash').showErrorMessage('Invalid note download link');
        return this.transitionTo('login');
      }
    },
    model(params) {
      var noteId = Ember.get(params, 'note_id');
      var token = Ember.get(params, 'token');
      var title = Ember.get(params, 'title');
      var host = _environment.default.API.baseUrl;
      var api_url = host + `/api/notes/${noteId}/download?t=${token}`;
      return fetch(api_url).then(response => {
        return response.json();
      }).then(body => {
        const presigned_url = body.presigned_get_url;
        return fetch(presigned_url, {
          method: 'GET',
          headers: {
            'Content-Type': 'text/plain'
          }
        });
      }).then(res => {
        return res.blob();
      }).then(blob => {
        var url = window.URL.createObjectURL(blob);
        return {
          downloadLink: url,
          noteTitle: title
        };
      }).catch(err => {
        return Ember.get(this, 'dash').handleServerErrors(err);
      });
    },
    actions: {
      download(url, title) {
        var filename = `${title}.mp3`;
        var element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }
  });
  _exports.default = _default;
});