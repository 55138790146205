define("dash/routes/devices/device/contacts/contact/index", ["exports", "dash/lib/authenticatedRoute", "dash/routes/devices/device/contacts/new"], function (_exports, _authenticatedRoute, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _authenticatedRoute.default.extend({
    dash: Ember.inject.service(),
    model() {
      return this.modelFor('devices.device.contacts.contact');
    },
    setupController(controller) {
      this._super(...arguments);
      return Ember.set(controller, 'submissionError', null);
    },
    actions: {
      onCancel(redirectPath) {
        if (Ember.get(this, 'controller.model.hasDirtyAttributes')) {
          Ember.get(this, 'controller.model').rollbackAttributes();
        }
        this.transitionTo(redirectPath);
      },
      saveContact() {
        const contact = Ember.get(this, 'controller.model');
        if (contact.isSaving) return;
        Ember.set(this, 'controller.submissionError', null);
        (0, _new.cleanContactNameFields)(contact).save().then(() => {
          Ember.getOwner(this).lookup('route:devices.device.contacts').refresh().then(() => {
            this.transitionTo('devices.device.contacts.index');
          });
        }).catch(errors => {
          Ember.get(this, 'dash').handleServerErrors(errors);
          Ember.set(this, 'controller.submissionError', 'Something went wrong. Please try again');
        });
      }
    }
  });
  _exports.default = _default;
});