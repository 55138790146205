define("dash/templates/components/support-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KdY/sSWP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"width\",\"100%\"],[11,\"height\",\"100%\"],[9],[0,\"\\n  \"],[7,\"rect\"],[11,\"class\",\"SupportWarning__Frame__Bg\"],[11,\"width\",\"100%\"],[11,\"height\",\"100%\"],[9],[10],[0,\"\\n  \"],[7,\"rect\"],[11,\"class\",\"SupportWarning__Frame__Fg\"],[11,\"width\",\"100%\"],[11,\"height\",\"100%\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dash/templates/components/support-warning.hbs"
    }
  });
  _exports.default = _default;
});